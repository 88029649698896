import React, { useState,useEffect } from 'react';
import './App.css';
import {fastapi_be, nodejs_db_api, fastapi_devURL} from './api';
import { useNavigate,useLocation,Prompt } from 'react-router-dom'; // Import useNavigate（useNavigateのインポート）
import { useAuth } from './AuthContext'; // Import useAuth（useAuthのインポート）
import { Box, Typography, Button, Alert, CircularProgress,Stack, List, ListItem , Container,Card, CardContent,Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Dialog, DialogContent,IconButton} from '@mui/material';
import { Link } from 'react-router-dom';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CommonLayout from './CommonLayout.js';
import usePopStateHandlerForUpload from './usePopStateHandlerForUpload';
import CloseIcon from '@mui/icons-material/Close';
import FullScreenPDFPreview from './FullScreenPDFPreview'; // Import the custom PDF viewer
import { createfileTokenAPKBlob } from './ApiSecurityUtils'


function UploadPDF() {
	const { user } = useAuth(); // Get the user from the auth context（AuthContextからユーザーを取得）
	const navigate = useNavigate(); // Initialize useNavigate（useNavigateの初期化）
    const [files, setFiles] = useState([]);
    const [response, setResponse] = useState(null);
    const [error, setError] = useState([]);
    //const [isUploadSuccessful, setIsUploadSuccessful] = useState(false);
    const [csvFiles, setCsvFiles] = useState([]);
    const [uploadLoading, setUploadLoading] = useState(false);  // Loading state for upload（アップロード状態の読み込み）
    const [csvLoading, setCsvLoading] = useState(false);        // Loading state for CSV creation（CSV作成のためのロード状態）
    
    const [openPDFSection, setOpenPDFSection] = useState(false); // State to manage PDF section collapse
    const location = useLocation(); // Get the current URL path

	const user_id = user?.data?.user_id;
	const csvfilertndays = user?.data?.csv_file_rtn_days;
	const storedUser = localStorage.getItem('user');
	const [isDownloading, setIsDownloading] = useState(false);
	
	const [pdfPreviewUrls, setPdfPreviewUrls] = useState([]);
	
	const [selectedFileIndex, setSelectedFileIndex] = useState(null);  // State to manage selected file index
	const [openDialog, setOpenDialog] = useState(false);
	
	// Detect if the page is being refreshed
    let isPageRefreshed = performance.navigation.type === performance.navigation.TYPE_RELOAD;
	
	let isProcessing = false;
	
	if (!storedUser){
			alert('You have been logged out');//ブラウザ管理の修正後に翻訳予定
          navigate('/'); // Redirect to login page（ログインページへリダイレクト）
    }
    
    useEffect(() => {
		setError(null);
       setFiles([]); // Optional: Reset files if needed
    
       
    // Detect if the page is being refreshed
    let isPageRefreshed = performance.navigation.type === performance.navigation.TYPE_RELOAD;

  }, []);
	
    const handleFileChange = (e) => {
        setFiles(e.target.files);
        setResponse(null);
        setCsvFiles([]);
        setCsvLoading(false);  // Stop loading（ロード停止）
        isProcessing = true;
        
        window.history.pushState({ isProcessing,isPageRefreshed, uploadLoading,csvLoading }, '');
        setError(null);
        
        const selectedFiles = Array.from(e.target.files);
        // Clear any previous PDF previews
        setPdfPreviewUrls([]);
        
        const previewPromises = selectedFiles.map((file) => {
        return new Promise((resolve, reject) => {
            if (file.type !== 'application/pdf') {
                reject('Only PDF files can be uploaded.');
            } else {
                const reader = new FileReader();
                reader.onload = (event) => {
                    resolve(event.target.result); // Resolve with the Data URL
                };
                reader.onerror = () => reject('Error reading file');
                reader.readAsDataURL(file); // Read the PDF file as a Data URL for preview
            }
        });
    });

    Promise.allSettled(previewPromises).then((results) => {
        const urls = [];
        results.forEach((result) => {
            if (result.status === 'fulfilled') {
                urls.push(result.value); // Only add successful results
            } else {
                setError(result.reason); // Set error message if file is not a PDF
            }
        });
        setPdfPreviewUrls(urls); // Update state with all successful preview URLs
    });
};

    const handleUpload = async () => {
		setUploadLoading(true);  // Start loading for upload（アップロードのための読み込み開始）
        isProcessing = true;
        // Push the initial state (e.g., current pdfFiles and isUploading)
        
    	window.history.pushState({ isProcessing,isPageRefreshed, uploadLoading }, '');
        //window.addEventListener('popstate', handlePopState);
        setResponse(null);  // Clear response while uploading（アップロード中のレスポンスをクリア）
        setCsvFiles([]);  // Clear previous CSV files（以前のCSVファイルを消去）
        
        //console.log(user_id);
        console.log(isProcessing);
        const formData = new FormData();
	//const identifierXId = await en_identifierXId(user_id);
	// Create a Blob containing the API key
        const fileTokenAPKBlob = await createfileTokenAPKBlob("FASTAPI");
	formData.append('fileTokenAPK', fileTokenAPKBlob, 'fileTokenAPK.txt');
    	formData.append('identifierX', user_id);
	    
        for (let i = 0; i < files.length; i++) {
            formData.append('files', files[i]);
        }
        try {
            const response = await fastapi_be.post(
		    `/upload-pdf`,
		    formData, 
		    {
                	headers: {
                    'Content-Type': 'multipart/form-data'
                	},
            	    });
            
            setResponse(response.data);
      		setError(null);
            
            // Enable "Create CSV" button only if all files are valid
           /* if (response.data.uploaded_files && response.data.uploaded_files.length > 0 && 
                (!response.data.error_filename || response.data.error_filename.length === 0)) {
                setIsUploadSuccessful(true);
            } else {
                setIsUploadSuccessful(false);
            }*/
                
            //console.log(response.data);
        } catch (error) {
            // Check if the error is a network error (i.e., no response from the server)
        if (!error.response) {
            setError('サーバーに接続できません。バックエンドサーバーが動作していない可能性があります。');  // 'Cannot connect to server. The backend server might not be running.'
        } else {
            console.error('Error uploading files:', error.message);
            setError('ファイルアップロードエラー: ' + error.message);
        }
            setResponse(null);
            //setIsUploadSuccessful(false);
        }finally {
            setUploadLoading(false);  // Stop loading
            
        }
        //window.removeEventListener('popstate', handlePopState);
    };
    
    const handleCreateCSV = async () => {
		setCsvLoading(true);  // Start loading（ロードスタート）
		isProcessing = true;
		setCsvFiles([]);  // Clear any previous CSV files（以前のCSVファイルを消去）
		
		window.history.pushState({ isProcessing,isPageRefreshed, csvLoading }, '');
        
	//const identifierXId = await en_identifierXId(user_id);
	// Create a Blob containing the API key
        const fileTokenAPKBlob = await createfileTokenAPKBlob("FASTAPI");
	// Create a FormData object to include the API key file
	const formData = new FormData();
	formData.append('fileTokenAPK', fileTokenAPKBlob, 'fileTokenAPK.txt');
    	formData.append('identifierX', user_id);
	console.log('Creating CSV...'); 
         try {
            const response = await fastapi_be.post(
		    `/create-csv`,
	    	   formData, // Pass the FormData object as the payload
	      	   {
	        	headers: {
	          	'Content-Type': 'multipart/form-data', // Indicate we're sending a file
	        	},
	           });
            console.log('CSV created successfully:');
           
            const csv_files = response.data.csv_files;
            
            console.log("Sending CSV files to Node.js API for insertion...");
            //console.log(csv_files);
            // Push the initial state (e.g., current pdfFiles and isUploading)
    	    //window.history.pushState({ csv_files, csvLoading }, '');

           // Create the encrypted API key Blob (for the Node.js API call)
	    const apiKeyBlob = await createfileTokenAPKBlob("NODEJS");

	  // Convert Blob to Base64 for transmission in the request
	   const base64ApiKey = await apiKeyBlob.text();
           
	 // Send the entire array of csv_files to the Node.js backend
        try {
            const nodejs_response = await nodejs_db_api.put('/insert-csv-data', {
		fileTokenAPK: base64ApiKey,  // Attach the encrypted API key
                user_id: user_id,
                csv_files: csv_files,  // Pass the whole array of CSV files
                csvfilertndays: csvfilertndays,
                csv_file_deleted: 'N'
            });
            
            //console.log("nodejs_response.data : ", nodejs_response.data);
            if (nodejs_response.data.status === 200) {
                setCsvFiles(nodejs_response.data.insertedFiles);  // Set the CSV files with expiry dates
                console.log('CSV files inserted successfully:', nodejs_response.data.insertedFiles);
            }
                
            } catch (insertError) {
                console.error(`Error inserting data for file ${csv_files}:`, insertError.message);
                setError('Error inserting CSV files: ' + insertError.message);
            }
        } catch (error) {
            console.error('Error creating CSV:', error.message);
            setError('CSV作成エラー: ' + error.message);
        }finally {
            setCsvLoading(false);  // Stop loading（ロード停止）
            isProcessing = false;
            isPageRefreshed=false;
        }
        //window.removeEventListener('popstate', handlePopState);
    };
    
    const handlePreviewPdf = (index) => {
        setSelectedFileIndex(index);
        setOpenDialog(true);
    };
    
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    
    // Function to toggle the PDF section
    const togglePDFSection = () => {
        setOpenPDFSection(prevOpen => !prevOpen);
    };
    
     const handleDownloadClick = () => {
    setIsDownloading(true);
  };

const handleDownload = async (fileName, userId) => {
	  try {
		setIsDownloading(true);
		
		//const identifierXId = await en_identifierXId(userId);
		
		// Create a Blob containing the API key
        	const fileTokenAPKBlob = await createfileTokenAPKBlob("FASTAPI");
	    
	    // Create a FormData object to include the API key file
	    const formData = new FormData();
	    formData.append('fileTokenAPK', fileTokenAPKBlob, 'fileTokenAPK.txt');
    	formData.append('identifierX', userId);
    	formData.append('fileName', fileName); // Add fileName as a parameter
	
	    // Send a POST request with the API key file and other required params
	    const response = await fastapi_be.post(
	      `/download-csv`, // Simpler endpoint without URL parameters
	      formData, // Pass the FormData object as the payload
	      {
	        responseType: 'blob', // Ensure binary response for the file download
	        headers: {
	          'Content-Type': 'multipart/form-data', // Indicate we're sending a file
	        },
	      }
	    );
	
	    // Create a blob from the response
	    const blob = new Blob([response.data], { type: 'application/octet-stream' });
	    const downloadLink = document.createElement('a');
	    downloadLink.href = window.URL.createObjectURL(blob);
	    downloadLink.setAttribute('download', fileName);
	    document.body.appendChild(downloadLink);
	    downloadLink.click();
	    document.body.removeChild(downloadLink);
	  } catch (error) {
	    console.error('Error downloading file:', error);
	  }
	};
    
    // Hook to manage back button during uploads
       usePopStateHandlerForUpload(isProcessing,isPageRefreshed,isDownloading);
    
    return (
        <CommonLayout user={user} openPDFSection={openPDFSection} togglePDFSection={togglePDFSection}>
            {/* Place your component-specific content here */}
            <Box sx={{ flexGrow: 1, padding: 2 }}>
            <Card className="pdfupload-athome">
        	<CardContent>
         	<Container>
            <Typography variant="h6" gutterBottom>
                at home PDFアップロード・CSV作成
            </Typography>
            
            <Box sx={{ padding: 2 }}>
      <Typography variant="h6" gutterBottom>
        PDF アップロード
      </Typography>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          border: '1px dashed grey',
          width: '65%', // Set to 50% of the parent container
          padding: 2,
          borderRadius: 1,
        }}
      >
        {/* Input for file selection */}
        <Box>
          <input
            type="file"
            multiple
            accept="application/pdf"
            id="pdf-upload"
            style={{ display: 'none' }} // Hide input by default
            onChange={handleFileChange}
            disabled={uploadLoading || csvLoading}
          />
          <label htmlFor="pdf-upload">
            <IconButton color="primary" component="span" disabled={uploadLoading || csvLoading}>
              <UploadFileIcon fontSize="large" />
            </IconButton>
          </label>
          
          <Typography variant="subtitle2" component="span" sx={{ marginLeft: -1 }}>
    		ファイル選択
  		 </Typography>

          {files.length > 0 && (
            <Typography variant="subtitle2" sx={{ marginTop: 1 }}>
              {files.length} ファイル選択済み
            </Typography>
          )}
        </Box>

        {/* Upload Button */}
        {/* Buttons for Upload and CSV Creation */}
       <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpload}
          disabled={files.length === 0 || uploadLoading || csvLoading}
        >
          {uploadLoading ? <CircularProgress size={24} /> : 'PDF アップロード'}
        </Button>
      
     {/*  {response && response.uploaded_files && response.uploaded_files.length > 0 && (*/}
        <Button variant="contained" onClick={handleCreateCSV} disabled={csvLoading || uploadLoading || !(response && response.uploaded_files && response.uploaded_files.length > 0)}>
              {csvLoading ? <CircularProgress size={24} /> : 'CSV作成'}
        </Button>
     {/* )}*/}
    </Box>
    </Box>
    </Box>
            {uploadLoading && <CircularProgress />}
            
            {/* Error Display */}
        	{error && (
            	<Alert severity="error" sx={{ marginTop: 2 }}>
                	{error}
            	</Alert>
        	)}

            {response && (
				<Box>
                    {csvLoading && <CircularProgress />}
                    {csvFiles.length > 0 && (
    <Box mt={2} sx={{
          	    marginTop : 2
               }}>
        <Typography variant="h6">ダウンロードCSV</Typography>
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>CSVファイル名</TableCell>
                        <TableCell>有効期限</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {csvFiles.map((csvFile, index) => (
						<TableRow
                        key={index}
                        sx={{
                            '&:hover': {
                                backgroundColor: '#f0f0f0', // 行全体にホバー効果
                                cursor: 'pointer', // マウスが指カーソルになる
                            },
                        }}
                    >
                            <TableCell>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography
                                        component="span" // Change from "a" to "span" since we won't use href
					color="primary"
                                        sx={{
    					textDecoration: 'none',
                                        color: 'inherit',
                                        display: 'block',
                                        margin: 0, // Add some padding for the hover effect
                                        '&:hover': {
                                        	backgroundColor: 'transparent', // リンク部分にホバー時の色変更を無効に
                                        	},
                                        fontFamily: 'Roboto, Arial, sans-serif', // フォントを統一
                                        fontSize: '14px', // フォントサイズを指定
  					}}
					onClick={() => handleDownload(csvFile.csv_filename, user?.data?.user_id)}
                                        onBlur={() => setIsDownloading(false)}
                                >
                                        {csvFile.csv_filename}
                                    </Typography>
                                </Stack>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body2" color="textSecondary">
                                    {new Intl.DateTimeFormat('ja-JP', {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                    }).format(new Date(csvFile.csv_expirydate))}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </Box>
)}               
                    <Typography variant="h6">PDFアップロード結果</Typography>
                    
                    {response.uploaded_files && response.uploaded_files.length > 0 && (
						<Box mt={2}>
                        <TableContainer component={Paper}>
                        <Table>
                           <TableHead>
                           <TableRow>
                               <TableCell>PDFファイル名</TableCell>
                           </TableRow>
                           </TableHead>
                           <TableBody>
             {response.uploaded_files.map((file, index) => (
                                <TableRow
                                    key={index}
                                    onClick={() => handlePreviewPdf(index)}  // Set the index of the clicked file
                                	sx={{
                                                '&:hover': {
                                                    backgroundColor: '#f0f0f0',
                                                    cursor: 'pointer',
                                                },
                                            }}>
                                    <TableCell>
                                    <Typography
                                    component="span"
                                    sx={{
                                        fontFamily: 'Roboto, Arial, sans-serif', // CSVファイルと同じフォントを使用
                                        fontSize: '14px', // フォントサイズを指定
                                    }}
                                >
                                {file}
                                </Typography>
                                </TableCell>
                                
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                </Box>
            )}
            
      {/* The Full-Screen PDF Viewer Component */}
      {!response.error_filename || response.error_filename.length === 0 ? (
      <FullScreenPDFPreview
        openDialog={openDialog} // Whether the dialog is open
        handleCloseDialog={handleCloseDialog} // Function to close the dialog
        pdfPreviewUrls={pdfPreviewUrls} // Array of PDF URLs
        selectedFileIndex={selectedFileIndex} // The currently selected PDF
        response={response} // The response containing uploaded file info
      />
      ) : null}
          
            
                    
                    {response.error_filename && response.error_filename.length > 0 && (
                        <Alert severity="error">
                            <Typography variant="h6">アットホームPDF以外のPDFが含まれています</Typography>
                            <List>
                                {response.error_filename.map((file, index) => (
                                    <ListItem key={index}>{file}</ListItem>
                                ))}
                            </List>
                        </Alert>
                    )}
                    
                    {files.length === 0 && response.errors.length === 0 && (
                        <Typography color="textSecondary">有効なファイルがアップロードされていません</Typography>
                    )}
                    
                    {console.log('Error:', error)}
                    {error && <Alert severity="error">{error}</Alert>}
                </Box>
            )}
            </Container>
            </CardContent>
            </Card>
          </Box>
       </CommonLayout>
    );
}

export default UploadPDF;
