import React, { useState, useEffect } from 'react';
import { Dialog, IconButton, Box, Typography, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PrintIcon from '@mui/icons-material/Print';
import DownloadIcon from '@mui/icons-material/Download';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import { Document, Page, pdfjs } from 'react-pdf';

// Import necessary CSS for text and annotation layers
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';


import workerSrc from 'pdfjs-dist/build/pdf.worker.min.js'; // Import the worker file

// Set the worker source to the local path
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

const FullScreenPDFPreview = ({ openDialog, handleCloseDialog, pdfPreviewUrls, selectedFileIndex, response }) => {
  const [zoomLevel, setZoomLevel] = useState(1.0);
  const [numPages, setNumPages] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); // Error state
  const ZOOM_STEP = 0.5;
  const MAX_ZOOM_LEVEL = 3.0;
  const MIN_ZOOM_LEVEL = 1.0;

  useEffect(() => {
    if (openDialog) {
      setZoomLevel(1.0);
      setNumPages(null);
      setLoading(true);
      setError(null); // Reset error state
    }
  }, [openDialog]);

  const handlePrint = () => {
    if (pdfPreviewUrls[selectedFileIndex]) {
      window.open(pdfPreviewUrls[selectedFileIndex], '_blank');
    }
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = pdfPreviewUrls[selectedFileIndex];
    link.download = response.uploaded_files[selectedFileIndex] || 'download.pdf';
    link.click();
  };

  const handleZoomIn = () => {
    setZoomLevel((prevZoom) => Math.min(prevZoom + ZOOM_STEP, MAX_ZOOM_LEVEL));
  };

  const handleZoomOut = () => {
    setZoomLevel((prevZoom) => Math.max(prevZoom - ZOOM_STEP, MIN_ZOOM_LEVEL));
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setLoading(false);
  };

  const onDocumentLoadError = (error) => {
    console.error('Error while loading PDF:', error);
    setLoading(false);
    setError('Failed to load PDF.'); // Set error message
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleCloseDialog}
      fullScreen
      PaperProps={{
        style: { backgroundColor: 'rgba(0, 0, 0, 0.8)', overflow: 'hidden' }
      }}
    >
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
      
       {/* Wrap the close icon and PDF name in a flex container */}
        <IconButton onClick={handleCloseDialog} sx={{ position: 'absolute', top: 16, left: 16, color: '#fff', zIndex: 2 }}>
          <CloseIcon />
        </IconButton>
        
         {/* PDF Icon */}
         <PictureAsPdfIcon sx={{ position: 'absolute', top: 23,left: 59,color: 'red', marginRight: 1, zIndex: 2  }} />
        
        <Typography variant="subtitle2" sx={{ position: 'absolute', top: 26, left: 86, color: '#fff', zIndex: 2 }}>
          {response.uploaded_files[selectedFileIndex]}
        </Typography>

        <IconButton onClick={handlePrint} sx={{ position: 'absolute', top: 16, right: 56, color: '#fff', zIndex: 2 }}>
          <PrintIcon />
        </IconButton>

        <IconButton onClick={handleDownload} sx={{ position: 'absolute', top: 16, right: 16, color: '#fff', zIndex: 2 }}>
          <DownloadIcon />
        </IconButton>

        <Box sx={{ maxHeight: '90vh', maxWidth: '90vw', display: 'flex', justifyContent: 'center', alignItems: 'center',marginTop: '50px' }}>
          {pdfPreviewUrls[selectedFileIndex] && (
            <Document
              file={pdfPreviewUrls[selectedFileIndex]}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={onDocumentLoadError}
            >
              <Page pageNumber={1} scale={zoomLevel} />
            </Document>
          )}
          {loading && <CircularProgress />}
          {error && <Typography color="error">{error}</Typography>} {/* Display error message */}
        </Box>

        <Box
          position="fixed"
          bottom={20}
          left="50%"
          sx={{
            transform: 'translateX(-50%)',
            zIndex: 2,
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            borderRadius: '30px',
            padding: '10px 20px',
          }}
        >
          <IconButton onClick={handleZoomOut} sx={{ color: 'white' }}>
            <ZoomOutIcon />
          </IconButton>
          <IconButton onClick={handleZoomIn} sx={{ color: 'white' }}>
            <ZoomInIcon />
          </IconButton>
        </Box>
      </Box>
    </Dialog>
  );
};

export default FullScreenPDFPreview;
