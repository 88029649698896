import CryptoJS from 'crypto-js';

// Function to encrypt the user ID
export const en_identifierXId = async (userId) => {
    try {
        const SECRET_KEY = CryptoJS.enc.Hex.parse(
            CryptoJS.SHA256(process.env.REACT_APP_ENCRYPT_KEY).toString(CryptoJS.enc.Hex)
        );
        const userIdString = String(userId); // Ensure userId is a string
        const iv = CryptoJS.lib.WordArray.random(16); // Generate a random IV
        const encrypted = CryptoJS.AES.encrypt(userIdString, SECRET_KEY, { iv });
        const encryptedData = iv.concat(encrypted.ciphertext); // Concatenate IV and ciphertext
        return CryptoJS.enc.Base64.stringify(encryptedData); // Encode to Base64
    } catch (error) {
        console.error('Error encrypting user ID:', error);
        throw new Error('Encryption failed');
    }
};

// Function to create a Blob for the API key
export const createfileTokenAPKBlob = async (ApiCall) => {
    try {
        const apiKey = ApiCall === "FASTAPI" 
    		? String(process.env.REACT_APP_FASTAPI_API_KEY) 
    		: String(process.env.REACT_APP_NODEJS_API_KEY);

	console.log("apiKey",apiKey);
	    
        if (!apiKey) throw new Error('API key is missing in environment variables');

        const SECRET_KEY = CryptoJS.enc.Hex.parse(
            CryptoJS.SHA256(process.env.REACT_APP_ENCRYPT_KEY).toString(CryptoJS.enc.Hex)
        );
		
		const iv = CryptoJS.lib.WordArray.random(16); // Random IV
		
		const encrypted = CryptoJS.AES.encrypt(apiKey, SECRET_KEY, { iv });
    
    	const encryptedData = iv.concat(encrypted.ciphertext);

        return new Blob([CryptoJS.enc.Base64.stringify(encryptedData)], { type: 'text/plain' });
    } catch (error) {
        console.error('Error creating API key Blob:', error);
        throw new Error('API key Blob creation failed');
    }
};
