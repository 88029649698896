import { React,useEffect} from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link, useNavigate,useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';

function Navigation() {
  const { user,logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    logout();  // Clear user context
    localStorage.removeItem('user');  // Clear localStorage
    localStorage.removeItem('sessionExpiry');
    localStorage.removeItem('companyData');
    navigate('/');  // Redirect to login after logout
  };
  

  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            {user ? (
              <>
                <Nav.Link onClick={handleLogout}>ログアウト</Nav.Link>
              </>
            ) : (
              <Nav.Link as={Link} to="/">ログイン</Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;
