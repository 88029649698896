// Login.js:
import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import {nodejs_db_api} from './api';
import { useAuth } from './AuthContext'
import './Login.css';
import { debounce } from 'lodash';
import PasswordReset from './PasswordReset';
import { createfileTokenAPKBlob } from './ApiSecurityUtils'



function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [showPasswordReset, setShowPasswordReset] = useState(false);
  const [isFirstTimeLogin, setIsFirstTimeLogin] = useState(false);
  const { login: authLogin } = useAuth(); // Rename to avoid confusion （混乱を避けるために名称を変更）
  const navigate = useNavigate();
  let login_attmpt_cnt =0;
  
  
  // Check if user details are present in localStorage and redirect to home page
  useEffect(() => {
    const user = localStorage.getItem('user');
    if (user) {
      navigate('/home');
    }
  }, [navigate]); // Empty dependency array to run this only once when component mounts

	const handlePasswordResetOpen = () => {
      setErrors('');
      setShowPasswordReset(true);
  };
  
  const handlePasswordResetClose = () => setShowPasswordReset(false);
  
  const handlePasswordResetSuccess = () => {
    /* Reset the password field when password is changed successfully
    （パスワードの変更に成功したら、パスワード・フィールドをリセット）*/
    setPassword('');
  };
  
  const debouncedupdLoginattmpt = debounce(async (email, login_attmpt_cnt) => {
	// Create the encrypted API key Blob (for the Node.js API call)
	const apiKeyBlob = await createfileTokenAPKBlob("NODEJS");

	// Convert Blob to Base64 for transmission in the request
	const base64ApiKey = await apiKeyBlob.text();
  	try{
		const usracctlockresp = await nodejs_db_api.put('/user-upd-loginattempt', {
			fileTokenAPK: base64ApiKey,  // Attach the encrypted API key
			user_email: email, 
			loginattmptcnt : login_attmpt_cnt
		});
	}catch(updateError){
		setErrors({ form: 'Failed to update login attempt count:' });
	}
}, 300); // 300ms debounce delay（300msのデバウンス遅延）


  const validateForm = () => {
    const newErrors = {};
    if (!email) {
		newErrors.email = 'メールアドレスは必須です。';
		setEmail('');
    }
    else if (!/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(email)) { 
		newErrors.email = '正しいメールアドレス形式で入力してください。';
		setEmail('');
	}
	
    if (!password) {
		newErrors.password = 'パスワードは必須です。';
		setPassword('');
	}
    else if (password.length < 8) {
		newErrors.password = 'パスワードは8文字以上で入力してください。';
		setPassword('');
	}
    else if (password.length > 16) {
		newErrors.password = 'パスワードは15文字以下で入力してください。';
		setPassword('');
	}
	// 英数字、記号が1文字以上含まれていない場合
	else if (!/^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()_+~\-=`{}[\]:";'<>?,./]).*$/.test(password)) {
      newErrors.password="英数字、記号を1文字以上含めてください。";
    }
    // 大文字、小文字が1文字以上含まれていない場合
    else if (!/(?=.*[a-z])(?=.*[A-Z])/.test(password)) {
      newErrors.password="大文字、小文字を1文字以上含めてください。";
    }
    // 連続した文字列の場合
    else if (/(\S)\1{1,}/.test(password)) {
      newErrors.password="同じ文字が連続して使用されています。";
    }
    // 連続した文字列の場合
    //else if (/(\w)\1\1/.test(password)) {
    //  newErrors.password="同じ文字が連続して使用されています";
    //}
	
    
    return newErrors;
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      setErrors({});
      // Create the encrypted API key Blob (for the Node.js API call)
	const apiKeyBlob = await createfileTokenAPKBlob("NODEJS");

	// Convert Blob to Base64 for transmission in the request
	const base64ApiKey = await apiKeyBlob.text();

	    //console.log("base64ApiKey",base64ApiKey);
	    
      try {
            const userData = await nodejs_db_api.post('/user-login', {
		    fileTokenAPK: base64ApiKey,  // Attach the encrypted API key	
		    user_email: email, 
		    password : password
		});
				
	// Check if it's the user's first-time login--（ ユーザーの初回ログインかどうかのチェック）
            if (userData.data.first_time_login === 'Y') {
				//alert('First-time login: Password change is mandatory.');
				alert('初回ログイン時： パスワード変更は必須です。');
                console.log('First-time login. Opening Password Reset Modal.');
                console.log('初回ログイン パスワードリセットモダルを開く');
                setIsFirstTimeLogin(true);
                setShowPasswordReset(true);  // Open the Password Reset modal（パスワードリセットモダールを開く）
            } else {
                // If not first-time login, proceed to home screen--（初回ログインでない場合は、ホーム画面に進む）
                authLogin(userData); // Set user in AuthContext（AuthContextにユーザーを設定する）
                console.log('Navigating to /home');
                console.log('ホームに移動する');
                navigate('/home');
            }
            
            //console.log('Login attempted with:', { userData,email, password });
        } catch (error) {
			console.log("loginFailCount ", error.response?.data?.login_attempt_cnt);
			if (error.response?.data?.login_attempt_cnt ===5){
				alert('アカウントをロックしました。システム管理者にご連絡ください。');
				setErrors({ form: 'アカウントをロックしました。システム管理者にご連絡ください。' });
			}
			else
			{
				if (error.response?.data?.err_code ==='USERNF'){
					setErrors({ form: '登録されたメールアドレスではありません。' });
				}
				else if (error.response?.data?.err_code ==='WRONGPWD'){
				setErrors({ form: '登録されたパスワードではありません。' });
            	
            	login_attmpt_cnt = error.response?.data?.login_attempt_cnt;
            	login_attmpt_cnt = login_attmpt_cnt +1;
            	
            	debouncedupdLoginattmpt(email,login_attmpt_cnt);
            	
            	}
            
            setEmail('');
            setPassword('');
    	   }
    
  		}
    }
  };


  return (
    <div className="login-wrapper">
      <div className="login-form-container">
        <h2 className="login-title">ログイン</h2>
        <Form onSubmit={handleSubmit} className="login-form">
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>メールアドレス</Form.Label>
            <Form.Control
              type="text"
              placeholder="メールアドレス"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              isInvalid={!!errors.email}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          </Form.Group>


          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>パスワード</Form.Label>
            <Form.Control
              type="password"
              placeholder="パスワード"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              isInvalid={!!errors.password}
            />
            <Form.Control.Feedback type="invalid">
              {errors.password}
            </Form.Control.Feedback>
          </Form.Group>

          <Button variant="primary" type="submit" className="login-button">
            ログイン
          </Button>
          <Button variant="link" onClick={handlePasswordResetOpen}>
          パスワードを忘れた場合
        </Button>
        </Form>
        
        {/* Password Reset Modal （パスワードリセットモーダル）*/}
      <PasswordReset 
      	show={showPasswordReset} 
      	handleClose={handlePasswordResetClose}
      	email={!isFirstTimeLogin ? '' : email} // Pass empty email if first-time login（初回ログイン時はメアドをブランク）
      	isFirstTimeLogin={isFirstTimeLogin} // Pass whether it's first-time login（初回ログインの有無を送信する）
      	isPwdChngFrmCompDtl={false}
      	onPasswordReset={handlePasswordResetSuccess} // Pass the success callback（成功のコールバックを返す）
      	 />
      
        {/* Display form-level error message （フォームレベルのエラーメッセージの表示）*/}
            {errors.form && <div className="alert alert-danger mt-3">{errors.form}</div>}
      </div>
    </div>
  );
}


export default Login;
