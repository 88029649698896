import React from 'react';
import {
    AppBar,
    Box,
    Button,
    Collapse,
    List,
    ListItem,
    ListItemText,
    Toolbar,
    Typography,
} from '@mui/material';

import { Link, useNavigate, useLocation } from 'react-router-dom';

const CommonLayout = ({ openPDFSection, togglePDFSection, user ,children}) => {
    const navigate = useNavigate();
    const location = useLocation();

    return (
		 <Box sx={{display: 'flex', flexDirection: 'column', flexGrow: 1 , minHeight: '90vh'}}>
                <AppBar position="static">
                <Toolbar>
                {/* Conditionally render the logo based on the current location */}
                {location.pathname !== '/home' && (
                <img
                    src="/home_logo.jpg"
                    alt="Home Logo"
                    className="logo"
                    style={{ cursor: 'pointer', marginRight: '1px' }}
                    onClick={() => navigate('/home')}
                />
                )}
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Estate Wiz
                    </Typography>
                </Toolbar>
                </AppBar>
                
                {/* height: 'calc(100% - 64px)'*/}
                <Box sx={{ display: 'flex',height: 'calc(100% - 64px)',flexGrow: 1, padding: 2 }}>
                <Box sx={{ width: '250px', padding: 2, borderRight: '5px solid #ccc',  minheight: '100%',flexShrink: 0 }}>
                    <Typography variant="h6">メニュー</Typography>
                    <Box sx={{ marginTop: 2 }}>
                    <Button component={Link} to="/home" disabled={location.pathname === '/home'} variant="outlined" fullWidth sx={{ marginBottom: 1 }}>
                            トップ
                        </Button>
                        {/* PDF Upload Button with Collapse */}
                        <Button variant="outlined" fullWidth sx={{ marginBottom: 1 }} onClick={togglePDFSection}>
                            取込ファイル作成
                        </Button>

                        {/* Collapsible section for PDF types */}
                        <Collapse in={openPDFSection}>
                            <List>
                                <ListItem component={Link} to="/upload-pdf/athome" 
                                 disabled={location.pathname === '/upload-pdf/athome'}>
                                    <ListItemText secondary="・at home PDF" 
                                    
                                    sx={{ '.MuiListItemText-secondary': { color: location.pathname === '/upload-pdf/athome' ? 'rgba(0, 0, 0, 0.38)' : 'inherit' } }} // This will make it inherit the primary text color
                                    />
                                </ListItem>
                                {/*
                                <ListItem component={Link} to="/upload-pdf/type2" disabled={location.pathname === '/upload-pdf/type2'}>
                                    <ListItemText secondary="・物件情報 PDF" 
                                    
                                    sx={{ '.MuiListItemText-secondary': { color: location.pathname === '/upload-pdf/type2' ? 'rgba(0, 0, 0, 0.38)' : 'inherit' } }} // This will make it inherit the primary text color
                                    />
                                </ListItem>*/}
                     		</List>
                        </Collapse>
                        <Button component={Link} to="/download-all-csv" disabled={location.pathname === '/download-all-csv'} variant="outlined" fullWidth sx={{ marginBottom: 1 }}>
                            過去作成CSVー覧 
                        </Button>
                        <Button component={Link} to="/customer-data" disabled={location.pathname === '/customer-data'} variant="outlined" fullWidth sx={{ marginBottom: 1 }}>
                            企業詳細情報
                        </Button>
                    </Box>
                </Box>
                <Box sx={{ flexGrow: 1, padding: 2 }}>
                    {children}
                </Box>
               </Box>
               
             {/* Footer Section */}
            <Box 
                component="footer" 
                sx={{ 
                    backgroundColor: '#f8f9fa', 
                    padding: 2, 
                    minHeight : '20%',
                    //textAlign: 'center', // Center align the text
                    //flexGrow: 1,
                    mt: 'auto' // Ensures the footer stays at the bottom
                }}
            >
                <Typography variant="body2" color="textSecondary">
                    © Copyright Infinity
                </Typography>
            </Box>
        </Box>
                
            );
          };
 export default CommonLayout;