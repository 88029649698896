import axios from 'axios';

const fastapi_devURL =process.env.REACT_APP_FASTAPI_URL;
const nodejs_db_api_devURL = process.env.REACT_APP_NODEJS_URL;
const fastapi_be = axios.create({
    baseURL: fastapi_devURL,
})

const nodejs_db_api = axios.create({
    baseURL: nodejs_db_api_devURL,
})

export {fastapi_be,nodejs_db_api,fastapi_devURL};