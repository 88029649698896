import React, { useState, useEffect } from 'react';
import { Link,useNavigate,useLocation } from 'react-router-dom';
import {nodejs_db_api} from './api'; // Import your API utility
import { useAuth } from './AuthContext'; // Import useAuth
import PasswordReset from './PasswordReset'; // Import PasswordReset component
import './App.css'; // Import the CSS file
import CommonLayout from './CommonLayout.js';
import { Box,Button, Card, CardContent, Grid, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Stack,Typography,AppBar,Toolbar, List, ListItem,Collapse,ListItemText } from '@mui/material';
import { createfileTokenAPKBlob } from './ApiSecurityUtils'

function CompanyDetail(){
	const navigate = useNavigate(); // Initialize useNavigate
    const [data, setData] = useState([]);
    const [frmtphno, setFrmtphno] = useState('');
    const [frmtfaxno, setFrmtfaxno] = useState('');
    const { user,logout } = useAuth(); // Get the user from the auth context
    const [showPasswordReset, setShowPasswordReset] = useState(false); // State to manage PasswordReset modal visibility
    
    const [openPDFSection, setOpenPDFSection] = useState(false); // State to manage PDF section collapse
    
    const location = useLocation(); // Get the current URL path
    
    //const [companies, setCompanies] = useState([]);
    //ゆくゆくはログインユーザーと会社情報に紐づく、キーIDを元に設定する
    let company_id = '';
    const [error, setError] = useState(null);
   
   company_id = user?.data?.company_id;
   //console.log("company_id",company_id);
   
   const storedUser = localStorage.getItem('user');
	
	if (!storedUser){
		alert('You have been logged out');
          navigate('/'); // Redirect to login page
    }
    
    const formatPostalCode = (postalCode) => {
  const cleaned = ('' + postalCode).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{4})$/);
  if (match) {
    return `〒${match[1]}-${match[2]}`;
    }
    return postalCode;
  };

const formatDate = (date) => {
  if (!date) return '';
  console.log("date",date);
  const [year, month, day] = date.split('T')[0].split('-');
  console.log(year);
  console.log(month);
  console.log(day);
  return `${year}年${month}月${day}日`;
};
   
   
    useEffect(() => {
		
		const formatPhoneNumber = async (number, postalcode) => {
			const num = String(number);
			const number1 = num.replace(/-/g, '');
  			if (!number1) return '';
  			
  		// Define the regex patterns for different phone number prefixes
  		const pattern3digitPrefix = /^(090|080|070|050)\d{8}$/; // Matches 090XXXXXXXX, 080XXXXXXXX, etc.
  		const pattern4digitPrefix = /^(0120)\d{7}$/; // Matches 0120XXXXXXX, 0800XXXXXXX
  
		try {
    	if (pattern4digitPrefix.test(number1)) {
			console.log("Pattern matched: 0120/0800");
      		// If the number matches the 4-digit prefix pattern, set the format directly
      		const frmt1 = 4;
      		const frmt3 = 4; // Last part is always 4 digits
      		const frmt2 = 11 - frmt1 - frmt3;

      		// Generate the regex for formatting the number
      		const regex = new RegExp(`(\\d{${frmt1}})(\\d{${frmt2}})(\\d{${frmt3}})`);
      		const result_num = number1.replace(regex, `$1-$2-$3`);

      		console.log("Formatted phone number:", result_num);
      		return result_num;

    	} else if (pattern3digitPrefix.test(number1)) {
      		console.log("Pattern matched: 090/080/070/050");
      		// If the number matches the 3-digit prefix pattern, set the format directly
      		const frmt1 = 3;
      		const frmt3 = 4; // Last part is always 4 digits
      		const frmt2 = 11 - frmt1 - frmt3;

      		// Generate the regex for formatting the number
      		const regex = new RegExp(`(\\d{${frmt1}})(\\d{${frmt2}})(\\d{${frmt3}})`);
      		const result_num = number1.replace(regex, `$1-$2-$3`);

      		console.log("Formatted phone number:", result_num);
      		return result_num;

    	} else {
    		console.log(" Phone number : postal code is", postalcode);

    		const response = await nodejs_db_api.post('/get-phfrmt-usg-postalcode', {
      							postal_code: postalcode
    						});

    		const phfrmtdgt = response.data;
    		//console.log("response.data : ",response.data)

    		if (phfrmtdgt.length > 0) {
      			let frmt1 = phfrmtdgt[0].phone_number_format; // first part of the format
      			let frmt3 = 4; // last part is fixed at 4 digits
      			let frmt2 = 10 - frmt1 - frmt3; // second part is derived from the remaining digits

     			// Build the dynamic regular expression pattern based on the format
				const regex = new RegExp(`(\\d{${frmt1}})(\\d{${frmt2}})(\\d{${frmt3}})`);

				//console.log("num", number1);
				//console.log("frmt1 : ", frmt1);
				//console.log("frmt2 : ", frmt2);
				//console.log("frmt3 : ", frmt3);

				//console.log("regex : ", regex);

				// Use the dynamically generated regex for replacement
				const result_num = number1.replace(regex, `$1-$2-$3`);

				//console.log("result_num : ", result_num);

      			//console.log("result_num : ",result_num);
      			return result_num;
    		}
    	  }
  		} catch (error) {
    		console.error("住所の取得に失敗しました", error);
  		}
  		return number1;
	};
    
    const formatFaxNumber = async (number,postalcode) => {
   const num = String(number);
	const number1 = num.replace(/-/g, '');
  if (!number1) return '';
  try {
    console.log(" Phone number : postal code is", postalcode);

    const response = await nodejs_db_api.post('/get-phfrmt-usg-postalcode', {
      postal_code: postalcode
    });

    const phfrmtdgt = response.data;
    console.log("phfrmtdgt.length : ",phfrmtdgt.length)

    if (phfrmtdgt.length > 0 ) {
      let frmt1 = phfrmtdgt[0].phone_number_format; // first part of the format
      let frmt3 = 4; // last part is fixed at 4 digits
      let frmt2 = 10 - frmt1 - frmt3; // second part is derived from the remaining digits

     // Build the dynamic regular expression pattern based on the format
	const regex = new RegExp(`(\\d{${frmt1}})(\\d{${frmt2}})(\\d{${frmt3}})`);

	//console.log("num", number1);
	//console.log("frmt1 : ", frmt1);
	//console.log("frmt2 : ", frmt2);
	//console.log("frmt3 : ", frmt3);

	//console.log("regex : ", regex);

	// Use the dynamically generated regex for replacement
	const result_num = number1.replace(regex, `$1-$2-$3`);

	//console.log("result_num : ", result_num);

      //console.log("result_num : ",result_num);
      return result_num;
    }
  } catch (error) {
    console.error("住所の取得に失敗しました", error);
  }

  return number1;
};
        const fetchData = async (company_id) => {
		// Create the encrypted API key Blob (for the Node.js API call)
		const apiKeyBlob = await createfileTokenAPKBlob("NODEJS");

		// Convert Blob to Base64 for transmission in the request
		const base64ApiKey = await apiKeyBlob.text();
          try {
		  
            const response = await nodejs_db_api.post('/get-company-data', {
			fileTokenAPK: base64ApiKey,  // Attach the encrypted API key	
		    	company_id: company_id
		});
            //console.log(response.data);
            //console.log(" response.data[0].postal_code : ", response.data[0].postal_code);
            //console.log("est date", response.data[0].est_date);
            const formattedPhoneNumber = await formatPhoneNumber(response.data[0].phone_number, response.data[0].postal_code);
        	const formattedFaxNumber = await formatFaxNumber(response.data[0].fax_number, response.data[0].postal_code);
        	
        	setFrmtphno(formattedPhoneNumber);
        	setFrmtfaxno(formattedFaxNumber);
		  console.log("formattedPhoneNumber",formattedPhoneNumber);
            setData(response.data);
            
          } catch (error) {
            setError(error.message);
            console.error('Error fetching data:', error);
          }
        };
        
        
        //console.log("company_id before fetchData :",company_id);
        if (company_id) {
        fetchData(company_id);}
      }, [company_id]);
      
      const handleEdit = () => {
        navigate('/company-info-crud', { state: { companyData: data } });
    };
    // Open the password reset modal
    const handlePasswordChange = () => {
        const confirmLogout = window.confirm(
        "パスワード変更に成功した場合、一度ログアウトされます。再度、新しいパスワードでログインを行ってください。"
    );
    console.log("email : ",user?.data?.user_email);
    if (confirmLogout) {
        setShowPasswordReset(true);
    }
    };

    // Handle successful password reset
    const handlePasswordResetSuccess = async () => {
        setShowPasswordReset(false);
        await logout(); // Log out the user
        navigate('/'); // Redirect to login page
    };

    // Close the password reset modal
    const handlePasswordResetClose = () => {
        setShowPasswordReset(false);
    };
    
    // Function to toggle the PDF section
    const togglePDFSection = () => {
        setOpenPDFSection(prevOpen => !prevOpen);
    };

    return(
        <div>
        <CommonLayout user={user} openPDFSection={openPDFSection} togglePDFSection={togglePDFSection}>
            {/* Place your component-specific content here */}
            <Box sx={{ flexGrow: 1, padding: 2 }}>
        	<Card className="company-info-form">
        <CardContent>
          <Typography variant="h6" gutterBottom>
            企業詳細情報照会
          </Typography>
          <div className="button-container">
            <Button variant="contained" color="primary" onClick={handleEdit}>
              企業詳細情報編集
            </Button>
            <Button variant="contained" color="secondary" onClick={handlePasswordChange}>
              パスワード変更
            </Button>
          </div>

          {error && <Typography color="error">Error: {error}</Typography>}

          <TableContainer component={Paper}>
  <Table>
    <TableBody>
      {data && Array.isArray(data) && data.map((item) => (
        <React.Fragment key={item.id || item.company_id}>
          {/* 会社名 */}
          <TableRow key={`${item.id}-company_name`}>
            <TableCell style={{ fontWeight: 'bold' }}>会社名</TableCell>
            <TableCell>{item.company_name}</TableCell>
          </TableRow>

          {/* 郵便番号 */}
          <TableRow key={`${item.id}-postal_code`}>
            <TableCell style={{ fontWeight: 'bold' }}>郵便番号</TableCell>
            <TableCell>{formatPostalCode(item?.postal_code)}</TableCell>
          </TableRow>

          {/* 住所 */}
          <TableRow key={`${item.id}-address`}>
            <TableCell style={{ fontWeight: 'bold' }}>住所</TableCell>
            <TableCell>{`${item.prefecture} ${item.city} ${item.alias} ${item.address} ${item.building}`}</TableCell>
          </TableRow>

          {/* 電話番号 */}
          <TableRow key={`${item.id}-phone_number`}>
            <TableCell style={{ fontWeight: 'bold' }}>電話番号</TableCell>
            <TableCell>{frmtphno}</TableCell>
          </TableRow>

          {/* FAX番号 */}
          <TableRow key={`${item.id}-fax_number`}>
            <TableCell style={{ fontWeight: 'bold' }}>FAX番号</TableCell>
            <TableCell>{frmtfaxno}</TableCell>
          </TableRow>

          {/* メールアドレス */}
          <TableRow key={`${item.id}-email`}>
            <TableCell style={{ fontWeight: 'bold' }}>メールアドレス</TableCell>
            <TableCell>{item.email}</TableCell>
          </TableRow>

          {/* 代表者 */}
          <TableRow key={`${item.id}-representative`}>
            <TableCell style={{ fontWeight: 'bold' }}>代表者</TableCell>
            <TableCell>{item.repr}</TableCell>
          </TableRow>

          {/* 設立年月日 */}
          <TableRow key={`${item.id}-establishment_date`}>
            <TableCell style={{ fontWeight: 'bold' }}>設立年月日</TableCell>
            <TableCell>{formatDate(item.est_date)}</TableCell>
          </TableRow>

          {/* 法人番号 */}
          <TableRow key={`${item.id}-corp_number`}>
            <TableCell style={{ fontWeight: 'bold' }}>法人番号</TableCell>
            <TableCell>{item.corpno}</TableCell>
          </TableRow>

          {/* 事業者登録番号 */}
          <TableRow key={`${item.id}-business_registration_number`}>
            <TableCell style={{ fontWeight: 'bold' }}>事業者登録番号</TableCell>
            <TableCell>{item.bussregno}</TableCell>
          </TableRow>
          
        </React.Fragment>
      ))}
    </TableBody>
  </Table>
</TableContainer>

        </CardContent>
      </Card>

      {/* Password Reset Modal */}
      <PasswordReset
        show={showPasswordReset}
        handleClose={handlePasswordResetClose}
        email={user?.data?.user_email || ''}
        isFirstTimeLogin={false}
        isPwdChngFrmCompDtl={true}
        onPasswordReset={handlePasswordResetSuccess}
      />
      </Box>
      </CommonLayout>
    </div>
  );
}

export default CompanyDetail;
