import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const usePopStateHandlerForUpload = (isProcessing,isPageRefreshed,isDownloading) => {
  const navigate = useNavigate();

  useEffect(() => {
    const handlePopState = (event) => {
      if (event.state || isProcessing) {
        // If files are being uploaded or are selected, alert the user
        console.log("Uploading in progress or files selected");
        alert("ファイルアップロードまたはCSV作成中です。処理が終了するまでお待ちください。");
        // Optionally, prevent navigation by not calling navigate
        // Or you can customize the logic based on your needs
        // Example: Use custom modals for confirmation
      } else {
        // Navigate back if no upload is happening
        console.log("navigate area");
        navigate(-1); // If no state or uploading is found, navigate back
      }
    };
    
     const handleBeforeUnload  = (event) =>{
    console.log('BeforeUnload event detected', event); // ページリロードや閉じる操作を確認
    console.log("isPageRefreshed : ",isPageRefreshed);
    if(!isDownloading){
    if (isPageRefreshed) {
      event.preventDefault();
      console.log("Uploading in progress or files selected");
      return (event.returnValue = '');
      }
     }
  };

    window.addEventListener('beforeunload', handleBeforeUnload);

    window.addEventListener('popstate', handlePopState);
    

    return () => {
      window.removeEventListener('popstate', handlePopState);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [navigate, isProcessing,isPageRefreshed,isDownloading]);
};

export default usePopStateHandlerForUpload;
