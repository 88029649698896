import React, { createContext, useState, useEffect,useContext } from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import {nodejs_db_api} from './api';
import { debounce } from 'lodash';
import SessionWebSoktUpd from './SessionWebSoktUpd';
import { SocketProvider } from './SocketContext'; // Import SocketProvider
import { createfileTokenAPKBlob } from './ApiSecurityUtils'

const AuthContext = createContext(null);


export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState(false); // 処理中フラグ
  const [socket, setSocket] = useState(null); // WebSocket instance
  
  // Detect if the page is being refreshed
    let isPageRefreshed = performance.navigation.type === performance.navigation.TYPE_RELOAD;
    
  
  // アップロードやCSV変換の処理中のフラグを操作する関数を作成
  const startProcessing = () => {
    console.log('Processing started'); // 処理が開始されたことを確認
    
    setIsProcessing(true);
  };
  const stopProcessing = () => {
    console.log('Processing stopped'); // 処理が終了したことを確認
    setIsProcessing(false);
  };
  
  const getTokyoTimestamp = () => {
    	// Create a new date object for the current time
    	const now = new Date();
    
    	// Tokyo is UTC+9
    	const offset = 9 * 60; // Offset in minutes

    	// Get the UTC time in minutes and add the offset
   	 	const tokyoTime = new Date(now.getTime() + (offset * 60 * 1000));

    	// Convert to ISO string
    	return tokyoTime.toISOString();
	};

  
    const debouncedupdLogintime = debounce(async (user_id, logintimestamp) => {
  try{
	  // Create the encrypted API key Blob (for the Node.js API call)
	const apiKeyBlob = await createfileTokenAPKBlob("NODEJS");

	// Convert Blob to Base64 for transmission in the request
	const base64ApiKey = await apiKeyBlob.text();
	  
	const usracctlockresp = await nodejs_db_api.put('/user-upd-logintimestamp', {
		fileTokenAPK: base64ApiKey,  // Attach the encrypted API key
		user_id: user_id, 
		logintimestamp : logintimestamp,
		login_attempt_failed : 0
		});
				}catch(updateError){
					setErrors({form: 'Login Timestamp Update Failed!. Please try again.' });
				}
}, 300); // 300ms debounce delay

const debouncedupdLogouttime = debounce(async (user_id, logouttimestamp, sessionid) => {
  try{
	  // Create the encrypted API key Blob (for the Node.js API call)
	const apiKeyBlob = await createfileTokenAPKBlob("NODEJS");

	// Convert Blob to Base64 for transmission in the request
	const base64ApiKey = await apiKeyBlob.text();
	  
	const usracctlockresp = await nodejs_db_api.put('/user-upd-logouttimestamp', {
		fileTokenAPK: base64ApiKey,  // Attach the encrypted API key		
		user_id: user_id, 
		logouttimestamp : logouttimestamp,
		session_id: sessionid
		});
	}catch(updateError){
		setErrors({form: 'Login Timestamp Update Failed!. Please try again.' });
				}
}, 300); // 300ms debounce delay

  const login = async (userData) => {
    setUser(userData);
    //console.log(userData);
    localStorage.setItem('user', JSON.stringify(userData));
    startProcessing();
    
    
    // Set session timeout (e.g., 1 hour)
    const expiryTime = new Date().getTime() + 60 * 60 * 1000;
    
    // Set session timeout for 15 minutes (15 * 60 * 1000 milliseconds)
	//const expiryTime = new Date().getTime() + 15 * 60 * 1000;
    
    localStorage.setItem('sessionExpiry', expiryTime);
    
    const updatelogintime = async () => {
  	//Update login timestamp
	// Function to get the current timestamp
    const formattedTimestamp = getTokyoTimestamp(); // Format timestamp as ISO string
   
    console.log(userData?.data?.user_id);
    console.log(formattedTimestamp);
    
    debouncedupdLogintime(userData?.data?.user_id,formattedTimestamp )
			
	};
	
	updatelogintime();
    
    try{
	// Create the encrypted API key Blob (for the Node.js API call)
	const apiKeyBlob = await createfileTokenAPKBlob("NODEJS");

	// Convert Blob to Base64 for transmission in the request
	const base64ApiKey = await apiKeyBlob.text();
	
	// Reset login attempt count
	const usracctlockresp = await nodejs_db_api.put('/user-upd-loginattempt', {
		fileTokenAPK: base64ApiKey,  // Attach the encrypted API key
		user_email: userData?.data?.user_email, 
		loginattmptcnt : 0
		});
	}catch(error1){
		setErrors({ form: 'Failed to update login attempt count:' });
	}
  };


  const logout = async () => {
	const updatelogouttime = async () => {
  	//Update logout timestamp
	// Function to get the current timestamp
    
    
    
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser) {
    const formattedTimestamp = getTokyoTimestamp(); // Format timestamp as ISO string
   
    //console.log(storedUser?.data?.user_id);
    //console.log(formattedTimestamp);
    console.log("logout session id : ",storedUser?.data?.session_id);
    
    debouncedupdLogouttime(storedUser?.data?.user_id,formattedTimestamp,storedUser?.data?.session_id);
		}	
	};
	
	updatelogouttime();
	
	 if (socket && socket.readyState === WebSocket.OPEN) {
      socket.close(); // Close the WebSocket connection during logout
      console.log('WebSocket closed during logout');
    }
	
    setUser(null);
    localStorage.removeItem('user');
    localStorage.removeItem('sessionExpiry');
    localStorage.removeItem('companyData');
    localStorage.removeItem('isProcessing');
    localStorage.removeItem('currentscreen');
    stopProcessing();
    navigate('/'); // Navigate to the login screen after logout
    
  };
  
  useEffect(() => {
    // Load user from localStorage if available
    const storedUser = localStorage.getItem('user');
    const storedExpiry = localStorage.getItem('sessionExpiry');
    
    let isNavigatingBack=false;
    
    if(storedUser && !user){
		setUser(JSON.parse(storedUser));
	}

    if (storedUser && storedExpiry) {
		const now = new Date().getTime();
		if (now < storedExpiry) {
			setUser(JSON.parse(storedUser));
		}else {
        localStorage.removeItem('user');
        localStorage.removeItem('sessionExpiry');}
		
    }
    

     // Handle back button navigation (popstate event)
  const handlePopState = (e) => {
    console.log('PopState event detected', e); // Back button detected
    if (1) {
      const confirmLeave = window.confirm('A process is ongoing. Do you want to leave?');
      console.log("confirmLeave : ",confirmLeave);
      if (1) {
       // e.preventDefault(); // Prevent navigation
        console.log(window.location);
      	console.log("history",window.history);
      	console.log("location ",window.history.state);
      	//window.history.go(1);
        //window.history.replaceState(null, null, "/customer-data"); // Keep user on the same page
        console.log('Navigation prevented due to ongoing processing');
        return;
      }
    }
    isNavigatingBack = true; // If user confirmed, allow navigation
  };

  function handleBeforeUnload (e ){
    console.log('BeforeUnload event detected', e); // ページリロードや閉じる操作を確認
    if (isPageRefreshed) {
      e.preventDefault();
      console.log('BeforeUnload prevented due to ongoing processing'); // 処理中のためリロードや閉じる操作を防止
      return (e.returnValue = ''); // 通常の確認ダイアログを表示
      }
      
  };

  //window.addEventListener('beforeunload', handleBeforeUnload);
  //window.addEventListener('popstate', handlePopState);

  //return () => {
    //window.removeEventListener('beforeunload', handleBeforeUnload);
    //window.removeEventListener('popstate', handlePopState);
  //};
}, [isProcessing]);





  return (
    //<AuthContext.Provider value={{ user, login, logout }}>
    <SocketProvider>
     <AuthContext.Provider value={{ user, login, logout, startProcessing, stopProcessing,socket,setSocket }}>
      <SessionWebSoktUpd />
      {children}
    </AuthContext.Provider>
    </SocketProvider>
  );
};


export const useAuth = () => useContext(AuthContext);
