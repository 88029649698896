import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Home from './Home';
import UploadPDF from './UploadPDF';
import DownloadAllCsv from './DownloadAllCsv';
import CompanyDetails from './CompanyDetails';
import CompanyInfoCRUD from './CompanyInfoCRUD';
import Login from './Login';
import Navigation from './Navigation'; // Import Navigation
import { AuthProvider } from './AuthContext';
import PasswordReset from './PasswordReset';

function App() {
  return (
    <Router>
    	<AuthProvider>
    	<Navigation /> {/* Include the Navigation bar */}
      	<Routes>
      	{/* Conditionally redirect to Home or Login based on user's login status */}
      	{/*<Route path="/" element={<Login />} />*/}
      	<Route path="/" element={<Login />} />
      	<Route path="/forgot-password" element={<PasswordReset />} />
        <Route path="/home" element={<Home />} />
        <Route path="/upload-pdf/athome" element={<UploadPDF />} />
        <Route path="/download-all-csv" element={<DownloadAllCsv />} />
        <Route path="/customer-data" element={<CompanyDetails />} />
        <Route path="/company-info-crud" element={<CompanyInfoCRUD />} />
      </Routes>
     </AuthProvider>
    </Router>
  );
}

export default App;
