import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from './AuthContext'; // Import useAuth
import { Box, Typography, Button, AppBar, Toolbar ,Collapse, List, ListItem, ListItemText} from '@mui/material'; // Import Material-UI components
import { useNavigate } from 'react-router-dom';
import CommonLayout from './CommonLayout.js';

function Home() {
    const { user, logout } = useAuth(); // Get the user from the auth context
    const navigate = useNavigate();
    const [announcements, setAnnouncements] = useState([]);
    const [openAnnouncements, setOpenAnnouncements] = useState({}); // State to track which announcements are open
    const [openPDFSection, setOpenPDFSection] = useState(false); // State to manage PDF section collapse

    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        //console.log("storedUser : homejs", storedUser);

        if (!storedUser) {
            alert('You have been logged out');
            navigate('/'); // Redirect to login page
        }

        const checkSession = () => {
            const storedExpiry = localStorage.getItem('sessionExpiry');

            if (storedExpiry) {
                const now = new Date().getTime();
                if (now >= storedExpiry) {
                    // Session has expired
                    //console.log(user);
                    logout();
                    navigate('/'); // Redirect to login page
                }
            }
        };

        // Optionally, you can also check the session periodically
        const interval = setInterval(checkSession, 10000); // Check every 10 seconds
        checkSession();
        
        // Fetch announcements from the JSON file
        const fetchAnnouncements = async () => {
            try {
                const response = await fetch('/announcements.json');
                const data = await response.json();
                const now = new Date();

                const validAnnouncements = data.filter(announcement => {
                    const startDate = new Date(announcement.startDate);
                    const endDate = new Date(announcement.endDate);
                    endDate.setHours(23, 59, 59, 999);  // 終了日を23:59:59に設定

                    return now >= startDate && now <= endDate;
                });

                setAnnouncements(validAnnouncements);
            } catch (error) {
                console.error('Failed to fetch announcements:', error);
            }
        };

        fetchAnnouncements();
        return () => clearInterval(interval); // Clear the interval on component unmount
    }, [user, logout, navigate]);

	 // 日付を YYYY年MM月DD日 にフォーマットする関数
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('ja-JP', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
    };

    // タイトルをクリックしたときに詳細を開閉する関数
    const toggleDetails = (index) => {
        setOpenAnnouncements(prevState => ({
            ...prevState,
            [index]: !prevState[index]  // 現在の状態をトグル（反転）する
        }));
    };
    
    // Function to toggle the PDF section
    const togglePDFSection = () => {
        setOpenPDFSection(prevOpen => !prevOpen);
    };

    return (
        <CommonLayout user={user} openPDFSection={openPDFSection} togglePDFSection={togglePDFSection}>
            {/* Place your component-specific content here */}

                <Box sx={{ flexGrow: 1, padding: 2 }}>
                	{/*}
                    <Typography variant="h5">
                        Welcome {user ? user.data.user_name : "Guest"} to the Infinity Web Application for PDF Conversion.
                    </Typography>
                    <Typography variant="body1" sx={{ marginTop: 1 }}>
                        Please select an option from the menu.
                    </Typography>*/}
                    
                    {/* お知らせ表示 */}
                    {announcements.length > 0 && (
                        <Box sx={{ marginTop: 2, padding: 2, border: '1px solid #ccc', borderRadius: '8px', backgroundColor: '#f9f9f9' }}>
                            <Typography variant="h6">お知らせ</Typography>
                            {announcements.map((announcement, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        marginTop: 1,
                                        padding: 1,
                                        borderBottom: '1px solid #ccc'
                                    }}
                                >
                                    {/* タイトル */}
                                    <Typography
                                        variant="body1"
                                        sx={{ cursor: 'pointer', fontWeight: 'bold' }}
                                        onClick={() => toggleDetails(index)}
                                    >
                                        {formatDate(announcement.startDate)} - {announcement.title}
                                    </Typography>

                                    {/* 詳細をクリックで開閉 */}
                                    <Collapse in={openAnnouncements[index]}>
                                        <Typography variant="body2" sx={{ marginTop: 1 }}>
                                            {announcement.details}
                                        </Typography>
                                        <Button
                                            variant="outlined"
                                            sx={{ marginTop: 1 }}
                                            onClick={() => toggleDetails(index)}
                                        >
                                            閉じる
                                        </Button>
                                    </Collapse>
                                </Box>
                            ))}
                        </Box>
                    )}
                </Box>
            </CommonLayout>
    );
}

export default Home;
