import React, { useState, useEffect } from 'react';
import './App.css';
import { Link,useLocation } from 'react-router-dom';
import {fastapi_be, nodejs_db_api, fastapi_devURL} from './api';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import CommonLayout from './CommonLayout.js';
import { Box, Typography, Button, Alert, CircularProgress,Stack, List, ListItem , Container,Card, CardContent,Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Dialog, DialogContent,IconButton} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { createfileTokenAPKBlob } from './ApiSecurityUtils'

function DownloadAllCsv() {
    const { user } = useAuth();
    const navigate = useNavigate(); // Initialize useNavigate
    const [csvFiles, setCsvFiles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null); // Track any errors
    
    const [openPDFSection, setOpenPDFSection] = useState(false); // State to manage PDF section collapse
    
    const location = useLocation(); // Get the current URL path
    
    const storedUser = localStorage.getItem('user');
	
	if (!storedUser){
			alert('You have been logged out');
          navigate('/'); // Redirect to login page
    }

    useEffect(() => {
        const fetchCSVFiles = async () => {
	    setLoading(true); // Show the spinner during the backend call
	    //const identifierXId = await en_identifierXId(user?.data?.user_id);
	    // Create a Blob containing the API key
            const fileTokenAPKBlob = await createfileTokenAPKBlob("FASTAPI");
	    // Create a FormData object to include the API key file
	    const formData = new FormData();
	    formData.append('fileTokenAPK', fileTokenAPKBlob, 'fileTokenAPK.txt');
    	    formData.append('identifierX', user?.data?.user_id);
		
            try {
                const response = await fastapi_be.post(
					`/download-all-csv`,
					formData, // Pass the FormData object as the payload
	      			{
	        			headers: {
	          			'Content-Type': 'multipart/form-data', // Indicate we're sending a file
	        			},
	        		}
				);
                
                const csvFilesList = response.data.csv_files;
                if (csvFilesList.length > 0) {
                    // Step 2: Call Node.js to get expiry dates for the CSV files
		    // Create the encrypted API key Blob (for the Node.js API call)
		     const apiKeyBlob = await createfileTokenAPKBlob("NODEJS");

		   // Convert Blob to Base64 for transmission in the request
		    const base64ApiKey = await apiKeyBlob.text();
                    
		    const nodejsResponse = await nodejs_db_api.post('/get-csv-expiry-dates', {
                        fileTokenAPK: base64ApiKey,  // Attach the encrypted API key
			user_id: user?.data?.user_id,
                        csv_files: csvFilesList
                    });

                    // Step 3: Set the CSV files with expiry dates in the state
                    setCsvFiles(nodejsResponse.data.csv_files_with_expiry); // Assuming response contains csv files and expiry dates
                 }else {
                    setCsvFiles([]); // No files found
                }
            } catch (error) {
                console.error('Error fetching CSV files:', error.message);
                setError('Error fetching CSV files or expiry dates.');
            } finally {
                setLoading(false);
            }
        };

        fetchCSVFiles();
        localStorage.setItem('isProcessing', false);
    }, [user?.data?.user_id]);
    
    // Function to toggle the PDF section
    const togglePDFSection = () => {
        setOpenPDFSection(prevOpen => !prevOpen);
    };
 const handleDownload = async (fileName, userId) => {
	  try {
		//const identifierXId = await en_identifierXId(userId);
		// Create a Blob containing the API key
	        const fileTokenAPKBlob = await createfileTokenAPKBlob("FASTAPI");
	    
	    	// Create a FormData object to include the API key file
	    const formData = new FormData();
	    formData.append('fileTokenAPK', fileTokenAPKBlob, 'fileTokenAPK.txt');
    	    formData.append('identifierX', userId);
    	    formData.append('fileName', fileName); // Add fileName as a parameter
	
	    // Send a POST request with the API key file and other required params
	    const response = await fastapi_be.post(
	      `/download-csv`, // Simpler endpoint without URL parameters
	      formData, // Pass the FormData object as the payload
	      {
	        responseType: 'blob', // Ensure binary response for the file download
	        headers: {
	          'Content-Type': 'multipart/form-data', // Indicate we're sending a file
	        },
	      }
	    );
	
	    // Create a blob from the response
	    const blob = new Blob([response.data], { type: 'application/octet-stream' });
	    const downloadLink = document.createElement('a');
	    downloadLink.href = window.URL.createObjectURL(blob);
	    downloadLink.setAttribute('download', fileName);
	    document.body.appendChild(downloadLink);
	    downloadLink.click();
	    document.body.removeChild(downloadLink);
	  } catch (error) {
	    console.error('Error downloading file:', error);
	  }
	};

    return (
		<div>
		<CommonLayout user={user} openPDFSection={openPDFSection} togglePDFSection={togglePDFSection}>
            {/* Place your component-specific content here */}
            
        <Box sx={{ flexGrow: 1, padding: 1 , width: '80%' }}>
            <Card className="downloadallcsv">
        	<CardContent>
         	<Container>        
        
        
          {loading ? (
                                    <Box display="flex" justifyContent="center" alignItems="center" height="200px">
                                        <CircularProgress /> {/* Show spinner while loading */}
                                    </Box>
                                ) : csvFiles.length > 0 ? (
        <Box mt={2}>
        <Typography variant="h6">過去作成CSV一覧</Typography>
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>CSVファイル名</TableCell>
                        <TableCell>有効期限</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
              {csvFiles.map((file, index) => (
                  <TableRow
                        key={index}
                        sx={{
                            '&:hover': {
                                backgroundColor: '#f0f0f0', // 行全体にホバー効果
                                cursor: 'pointer', // マウスが指カーソルになる
                            },
                        }}
                    >
                  <TableCell>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography
  			component="span" // Change from "a" to "span" since we won't use href
  			color="primary"
  			sx={{
    			textDecoration: 'none',
    			color: 'inherit',
    			display: 'inline-block',
    			padding: '2px 4px',
    			'&:hover': {
      				backgroundColor: '#f0f0f0',
      				cursor: 'pointer',
    				},
  			}}
  			onClick={() => handleDownload(file.file_name, user?.data?.user_id)}
			>
  			{file.file_name}
		   </Typography>
                   </Stack>
                 </TableCell>
                 <TableCell>
                 <Typography variant="body2" color="textSecondary">
                     {new Intl.DateTimeFormat('ja-JP', {
                           year: 'numeric',
                           month: 'long',
                           day: 'numeric',
                     }).format(new Date(file.expiry_date))}
                 </Typography>
                 </TableCell>
              </TableRow>
            ))}
            </TableBody>
            </Table>
        </TableContainer>
       </Box>
       ) : (
           <Typography variant="body2">No CSV files available.</Typography>
       )}
                                
                        {error && (
                        <Alert severity="error">
                            <Typography variant="h6">Download CSV Error</Typography>
                            <List>
                                {error}
                            </List>
                        </Alert>
                    )}
                    
                    
                   </Container>
                   </CardContent>
                    </Card>
                </Box>
            </CommonLayout>
        </div>
    );
}

export default DownloadAllCsv;
