// src/SessionWebSoktUpd.js
import React, { useEffect, useState } from 'react';
import { useAuth } from './AuthContext'; // Import useAuth（useAuthのインポート）
import { useNavigate } from 'react-router-dom';

const SessionWebSoktUpd = () => {
  const {user, setUser, setSocket, socket}  = useAuth(); // Get the user from the auth context（AuthContextからユーザーを取得）
  const navigate = useNavigate();

  useEffect(() => {
    // Initialize WebSocket connection
    console.log("process.env.REACT_APP_WEBSOCKET_URL",process.env.REACT_APP_WEBSOCKET_URL);
    const ws = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL); // Connect to the WebSocket server on port 5000（ポート5000でWebSocketサーバーに接続）
    
    // Save the WebSocket instance in AuthContext（AuthContextにWebSocketインスタンスを保存）
    setSocket(ws);

    ws.onopen = () => {
      const session_id = user?.data?.session_id; // Replace with actual session ID（実際のセッションIDに置換）
      ws.send(JSON.stringify({ session_id }));

      // Handle socket messages
      ws.onmessage = (event) => {
		const session_id1 = user?.data?.session_id; // Replace with actual session ID（実際のセッションIDに置換）
        const data = JSON.parse(event.data);
        //console.log('Received message:', data);
        //console.log("data.session_id ",data.session_id);
        //console.log("session_id ",session_id1);
        if (data.message === 'You have been logged out due to a new login') {
		 alert('You will be logged out soon due to a new login session.');
    	setTimeout(() => {
      		localStorage.removeItem('user');
      		localStorage.removeItem('sessionExpiry');
      		ws.close();
      		navigate('/'); // Redirect to login page after logout（ログアウト後にログインページへリダイレクト）
    		}, 1000); // Wait for 1 seconds before logging out（ ログアウトする前に1秒待つ）
        }
      };
    };

    ws.onclose = () => {
      console.log('WebSocket connection closed');
    };

    // Clean up on component unmount（コンポーネントのアンマウント時のクリーンアップ）
    return () => {
      //ws.close();
    };
  }, [ user,setUser, navigate, setSocket]);

  return <div></div>;
};

export default SessionWebSoktUpd;
