import React, { useState, useEffect } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import { nodejs_db_api } from './api';
import './PasswordReset.css';
import { createfileTokenAPKBlob } from './ApiSecurityUtils'

const PasswordReset = ({ show, handleClose, email, isFirstTimeLogin,isPwdChngFrmCompDtl, onPasswordReset }) => {
  const [inputEmail, setInputEmail] = useState(email);
  const [currentPassword, setCurrentPassword] = useState('');  // New state for current password
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState({});
  
  console.log("isFirstTimeLogin ",isFirstTimeLogin);
  console.log("email",email);
    
  useEffect(() => {
    // If it's not first-time login, the email should be empty
    if (isFirstTimeLogin ===true || isPwdChngFrmCompDtl===true){
    setInputEmail(email);
    setCurrentPassword('');
    setPassword('');
    setErrors('');
    }else{setInputEmail('')
    setPassword('');
    setConfirmPassword('');
    setErrors('');
    }
  }, [isFirstTimeLogin,isPwdChngFrmCompDtl,email]);

  const validatePasswords = () => {
    const newErrors = {};
    
     if (!inputEmail) {
      newErrors.email = 'メールアドレスは必須です。';
    
    }else if (!/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(inputEmail)) { 
		newErrors.email = '正しいメールアドレス形式で入力してください。';
		setInputEmail('');
	}
	
	if (!currentPassword && (isFirstTimeLogin || isPwdChngFrmCompDtl)) {
      newErrors.currentPassword = '現在のパスワードは必須です。';  // Error for current password
    }
    
     if (!password) {
		newErrors.password = 'パスワードは必須です。';
		setPassword('');
	}
    else if (password.length < 8) {
		newErrors.password = 'パスワードは8文字以上で入力してください。';
		setPassword('');
	}
    else if (password.length > 16) {
		newErrors.password = 'パスワードは15文字以下で入力してください。';
		setPassword('');
	}// 英数字、記号が1文字以上含まれていない場合
	else if (!/^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()_+~\-=`{}[\]:";'<>?,./]).*$/.test(password)) {
      newErrors.password= "英数字、記号を1文字以上含めてください。";
    }
    // 大文字、小文字が1文字以上含まれていない場合
    else if (!/(?=.*[a-z])(?=.*[A-Z])/.test(password)) {
      newErrors.password="大文字、小文字を1文字以上含めてください。";
    }
    // 連続した文字列の場合
    else if (/(\S)\1{1,}/.test(password)) {
      newErrors.password="同じ文字が連続して使用されています。";
    }
    // 連続した文字列の場合
    //else if (/(\w)\1\1/.test(password)) {
    //  newErrors.password="同じ文字が連続して使用されています";
    //}
    
     if (!confirmPassword) {
		newErrors.confirmPassword = 'パスワードは必須です。';
		setConfirmPassword('');
	}
    
	if (password !== confirmPassword) {
      newErrors.confirmPassword = '新パスワードと確認用パスワードが一致しません。';
      setConfirmPassword('');
    }else if(confirmPassword && (isFirstTimeLogin || isPwdChngFrmCompDtl) && confirmPassword === currentPassword){
		newErrors.confirmPassword = '現在のパスワードと同じです。';
	  }else if(confirmPassword && (isFirstTimeLogin || isPwdChngFrmCompDtl) && confirmPassword.toLowerCase() === currentPassword.toLowerCase()){
		newErrors.confirmPassword = '他の英数字、記号を含めてください。';
	  }
    
    // Check if current password and new password are the same regardless of case
    if (currentPassword && (isFirstTimeLogin || isPwdChngFrmCompDtl) && password === currentPassword) {
      newErrors.password = '現在のパスワードと同一です。';
    }else 
    if (currentPassword && (isFirstTimeLogin || isPwdChngFrmCompDtl) && password.toLowerCase() === currentPassword.toLowerCase()) {
      // Check if current password and new password are the same regardless of case
      newErrors.password = '他の英数字、記号を含めてください。';
    }
    
    
    
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let forgot_password='N';
    const formErrors = validatePasswords();
    if (Object.keys(formErrors).length === 0) {
      try {
        //console.log("email : ",inputEmail);
        //console.log("password : ",password);
        
        if (isPwdChngFrmCompDtl ===true){
			forgot_password='N';
		}else if (isPwdChngFrmCompDtl ===false && isFirstTimeLogin===false){
			forgot_password='Y';
		}
        
        //console.log("currentPassword ",currentPassword);

	// Create the encrypted API key Blob (for the Node.js API call)
	const apiKeyBlob = await createfileTokenAPKBlob("NODEJS");

	// Convert Blob to Base64 for transmission in the request
	const base64ApiKey = await apiKeyBlob.text();
	      
        await nodejs_db_api.put('/user-reset-password', 
		{
		fileTokenAPK: base64ApiKey,  // Attach the encrypted API key
		email:inputEmail, 
		currentPassword: currentPassword,
		newpassword:password ,
		forgot_password:forgot_password
		});
        //alert('Password changed successfully.');
        alert('パスワード変更が成功しました。');
        // Reset the state after successful password change--（パスワード変更成功後、状態をリセット）
        setInputEmail('');
        setCurrentPassword('');
        setPassword('');
        setConfirmPassword('');
        
        // Notify Login component about the successful reset--（ リセットが成功したことをLoginコンポーネントに通知）
        if (onPasswordReset) {
          onPasswordReset(); 
        }
        
        handleClose();
      } catch (error) {
        //setErrors({ form: 'パスワード変更が失敗しました。再度設定してください' });
        console.log(error.response);
        // Check the status code of the error response
      if (error.response && error.response.status === 404) {
        setErrors({ form: '登録されたメールアドレスではありません。' }); // User not found
      } else if (error.response && error.response.status === 401){
        // For other errors, display a generic error message
        setErrors({ form: 'パスワード変更が失敗しました。再度設定してください。' }); // Password reset failed
      }else if (error.response && error.response.status === 402){
        // For other errors, display a generic error message
        setErrors({ form: '現在のパスワードが一致しません。正しいパスワードを入力してください。' }); // Password reset failed
      }
      }
    } else {
      setErrors(formErrors);
    }
  };

	const handleModalClose = () => {
    // Reset all state variables to initial values when closing the modal
    setInputEmail('');
    setPassword('');
    setConfirmPassword('');
    setErrors({});
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleModalClose} centered backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>パスワード変更</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
        {(!isFirstTimeLogin || !isPwdChngFrmCompDtl) && (
            <Form.Group controlId="formEmail">
              <Form.Label>メールアドレス</Form.Label>
              <Form.Control
                type="text"
                placeholder="メールアドレスを入力"
                value={inputEmail}
                onChange={(e) => setInputEmail(e.target.value)}
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
          )}
          {(isFirstTimeLogin || isPwdChngFrmCompDtl) && (
            <Form.Group controlId="formCurrentPassword"> {/* Current password field */}
              <Form.Label>現在のパスワード</Form.Label>
              <Form.Control
                type="password"
                placeholder="現在のパスワードを入力"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                isInvalid={!!errors.currentPassword}
              />
              <Form.Control.Feedback type="invalid">{errors.currentPassword}</Form.Control.Feedback>
            </Form.Group>
          )}
          <Form.Group controlId="formPassword">
            <Form.Label>新しいパスワード</Form.Label>
            <Form.Control
              type="password"
              placeholder="新しいパスワードを入力"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              isInvalid={!!errors.password}
            />
            <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formConfirmPassword">
            <Form.Label>新しいパスワード(確認用)</Form.Label>
            <Form.Control
              type="password"
              placeholder="新しいパスワード(確認用)を入力"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              isInvalid={!!errors.confirmPassword}
            />
            <Form.Control.Feedback type="invalid">{errors.confirmPassword}</Form.Control.Feedback>
          </Form.Group>

          <Button variant="primary" type="submit">
            パスワード変更
          </Button>
          {errors.form && <div className="alert alert-danger mt-3">{errors.form}</div>}
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default PasswordReset;
