import './App.css';
import {nodejs_db_api} from './api'; // Import your API utility
import React, { useState, useEffect } from 'react';
import { Link,useNavigate,useLocation } from 'react-router-dom'; // Import useNavigate
import { useAuth } from './AuthContext'; // Import useAuth
import CommonLayout from './CommonLayout.js';
import {Card, CardContent,Button,TextField,Typography,Container,Box,Alert, CircularProgress,AppBar,Toolbar,List, ListItem,Collapse,ListItemText} from '@mui/material'; // Import Material-UI components
import usePopStateHandlercompany from './usePopStateHandlercompany'; // Import the custom hook
import { createfileTokenAPKBlob } from './ApiSecurityUtils'

function CompanyInfo() {
	const navigate = useNavigate(); // Initialize useNavigate
	const location = useLocation();

	let company_id = '';
	const { user,logout } = useAuth(); // Get the user from the auth context
	const { companyData } = location.state || {};
	const storedUser = localStorage.getItem('user');
	
	// Detect if the page is being refreshed
    let isPageRefreshed = performance.navigation.type === performance.navigation.TYPE_RELOAD;
	
	const [openPDFSection, setOpenPDFSection] = useState(false); // State to manage PDF section collapse
	
	
	if (!storedUser){
			alert('You have been logged out');
          navigate('/'); // Redirect to login page
    }

    
    const [company, setCompany] = useState({
    company_name: '', postal_code: '', prefecture: '', city: '', alias: '', address: '', building: '',
    phone_number: '', fax: '', email: '', repr: '', est_date: '', corpno: '', bussregno: ''
  });
    
    const [error, setError] = useState([]);
    const [success, setSuccess] = useState(null);
    const [loading, setLoading] = useState(false);
    const [addresses, setAddresses] = useState([]);
    const [phnofrmt, setphnofrmt] = useState([]);
    
    
    
    company_id = user?.data?.company_id;
    
    
const formatPhoneNumber = async (number, postalcode) => {
	const num = String(number);
	const number1 = num.replace(/-/g, '');
  if (!number1) return '';
  
   // Define the regex patterns for different phone number prefixes
  const pattern3digitPrefix = /^(090|080|070|050)\d{8}$/; // Matches 090XXXXXXXX, 080XXXXXXXX, etc.
  const pattern4digitPrefix = /^(0120)\d{7}$/; // Matches 0120XXXXXXX, 0800XXXXXXX
  
	try {
    if (pattern4digitPrefix.test(number1)) {
		console.log("Pattern matched: 0120/0800");
      // If the number matches the 4-digit prefix pattern, set the format directly
      const frmt1 = 4;
      const frmt3 = 4; // Last part is always 4 digits
      const frmt2 = 11 - frmt1 - frmt3;

      // Generate the regex for formatting the number
      const regex = new RegExp(`(\\d{${frmt1}})(\\d{${frmt2}})(\\d{${frmt3}})`);
      const result_num = number1.replace(regex, `$1-$2-$3`);

      console.log("Formatted phone number:", result_num);
      return result_num;

    } else if (pattern3digitPrefix.test(number1)) {
      console.log("Pattern matched: 090/080/070/050");
      
      // If the number matches the 3-digit prefix pattern, set the format directly
      const frmt1 = 3;
      const frmt3 = 4; // Last part is always 4 digits
      const frmt2 = 11 - frmt1 - frmt3;

      // Generate the regex for formatting the number
      const regex = new RegExp(`(\\d{${frmt1}})(\\d{${frmt2}})(\\d{${frmt3}})`);
      const result_num = number1.replace(regex, `$1-$2-$3`);

      console.log("Formatted phone number:", result_num);
      return result_num;

    } else {
    console.log(" Phone number : postal code is", postalcode);

    const response = await nodejs_db_api.post('/get-phfrmt-usg-postalcode', {
      postal_code: postalcode
    });

    const phfrmtdgt = response.data;
    console.log("response.data : ",response.data)

    if (phfrmtdgt.length > 0) {
      let frmt1 = phfrmtdgt[0].phone_number_format; // first part of the format
      let frmt3 = 4; // last part is fixed at 4 digits
      let frmt2 = 10 - frmt1 - frmt3; // second part is derived from the remaining digits

     // Build the dynamic regular expression pattern based on the format
	const regex = new RegExp(`(\\d{${frmt1}})(\\d{${frmt2}})(\\d{${frmt3}})`);

	console.log("num", number1);
	console.log("frmt1 : ", frmt1);
	console.log("frmt2 : ", frmt2);
	console.log("frmt3 : ", frmt3);

	console.log("regex : ", regex);

	// Use the dynamically generated regex for replacement
	const result_num = number1.replace(regex, `$1-$2-$3`);

	console.log("result_num : ", result_num);

      console.log("result_num : ",result_num);
      return result_num;
    }
    }
  } catch (error) {
    console.error("住所の取得に失敗しました", error);
  }
  return number1;
};


const formatFaxNumber = async (number,postalcode) => {
   const num = String(number);
	const number1 = num.replace(/-/g, '');
  if (!number1) return '';
  try {
    console.log(" Phone number : postal code is", postalcode);

    const response = await nodejs_db_api.post('/get-phfrmt-usg-postalcode', {
      postal_code: postalcode
    });

    const phfrmtdgt = response.data;
    console.log("response.data : ",response.data)

    if (phfrmtdgt.length > 0) {
      let frmt1 = phfrmtdgt[0].phone_number_format; // first part of the format
      let frmt3 = 4; // last part is fixed at 4 digits
      let frmt2 = 10 - frmt1 - frmt3; // second part is derived from the remaining digits

     // Build the dynamic regular expression pattern based on the format
	const regex = new RegExp(`(\\d{${frmt1}})(\\d{${frmt2}})(\\d{${frmt3}})`);

	console.log("num", number1);
	console.log("frmt1 : ", frmt1);
	console.log("frmt2 : ", frmt2);
	console.log("frmt3 : ", frmt3);

	console.log("regex : ", regex);

	// Use the dynamically generated regex for replacement
	const result_num = number1.replace(regex, `$1-$2-$3`);

	console.log("result_num : ", result_num);

      console.log("result_num : ",result_num);
      return result_num;
    }
  } catch (error) {
    console.error("住所の取得に失敗しました", error);
  }

  return number1;
};

const formatDate = (date) => {
  if (!date) return '';
  // Split the date from 'T'
  const [datePart] = date.split('T');
  // Remove hyphens and format using the regular expression
  const numericDate = datePart.replace(/-/g, ''); // Remove hyphens from YYYY-MM-DD
  // Format using regular expression to add hyphens back
  const formattedDate = numericDate.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
  return formattedDate;
};
    
    // Added async logic inside useEffect
  useEffect(() => {
	
    const loadCompanyData = async () => {
		const storedCompanyData = JSON.parse(localStorage.getItem('companyData')); // Retrieve stored data
		//console.log("storedCompanyData",storedCompanyData);
      if (location.state?.companyData) {
        const companyData = location.state?.companyData[0];
 
        // Await the async formatting functions
        const formattedPhoneNumber = await formatPhoneNumber(companyData.phone_number, companyData.postal_code);
        const formattedFaxNumber = await formatFaxNumber(companyData.fax_number, companyData.postal_code);
        const formattedEstDate = formatDate(companyData.est_date); // If this was async, you would use await here

        // Update the company state with formatted values
        setCompany({
          ...companyData,
          phone_number: formattedPhoneNumber,
          fax_number: formattedFaxNumber,
          est_date: formattedEstDate,
        });
      localStorage.setItem('companyData', JSON.stringify(companyData));
      }else if (storedCompanyData){
		const companyData = storedCompanyData;
		//console.log("companyData",companyData);
		 // Await the async formatting functions
        const formattedPhoneNumber = await formatPhoneNumber(companyData.phone_number, companyData.postal_code);
        const formattedFaxNumber = await formatFaxNumber(companyData.fax_number, companyData.postal_code);
        const formattedEstDate = formatDate(companyData.est_date); // If this was async, you would use await here

        // Update the company state with formatted values
        setCompany({
          ...companyData,
          phone_number: formattedPhoneNumber,
          fax_number: formattedFaxNumber,
          est_date: formattedEstDate,
        });
      localStorage.setItem('companyData', JSON.stringify(companyData)); 
	  }
    };
    loadCompanyData(); // Invoke the async function
    localStorage.setItem('isProcessing', true);
  }, [location.state]);  

    
    // Function to fetch address from postal code
  const fetchAddress = async (postal_code) => {
    try {
		console.log(" postal_code is", postal_code);
      const response = await nodejs_db_api.post('/get-address-usg-postalcode', {
                postal_code: postal_code
            });
      const addressData = response.data;
      setAddresses(addressData);
      if (addressData.length === 1) {
        const { prefecture, city, alias } = addressData[0];
        
        // Format the phone number after fetching the address
      const formattedPhoneNumber1 = await formatPhoneNumber(company.phone_number, postal_code);
      const formattedFaxNumber1 = await formatFaxNumber(company.fax_number, postal_code);
      
      console.log("formattedPhoneNumber1 : ",formattedPhoneNumber1);
      console.log("formattedFaxNumber1 : ",formattedFaxNumber1);
        
       setCompany(prev => ({ ...prev, prefecture, city, alias,phone_number: formattedPhoneNumber1,fax_number: formattedFaxNumber1 }));
      }
    } catch (error) {
      console.error("住所の取得に失敗しました", error);
    }
  };
  
  const handleAddressSelect = (e) => {
    const selectedIndex = e.target.value;
    if (selectedIndex === '' || !addresses[selectedIndex]) return;
    const selected = addresses[selectedIndex];
    setCompany(prev => ({
      ...prev,
      prefecture: selected.prefecture,
      city: selected.city,
      alias: selected.alias
    }));
  };
  
  const handleChange = async (e) => {
    const { name, value } = e.target;
    	
    if (name === 'postal_code' && value.length === 7) {
		console.log(value);
      fetchAddress(value);
    }
   // Handle formatting for phone number and fax number
    if (name === 'phone_number' ) {
        const formattedValue = value.replace(/[^0-9]/g, ''); // Remove all non-numeric characters
        const withphfrmtval = await formatPhoneNumber(formattedValue, company.postal_code)
        setCompany(prev => ({ ...prev, [name]:withphfrmtval  }));
    } else if (name === 'fax_number') {
		const formattedValue = value.replace(/[^0-9]/g, ''); // Remove all non-numeric characters
        const withphfrmtval = await formatFaxNumber(formattedValue, company.postal_code)
        setCompany(prev => ({ ...prev, [name]:withphfrmtval  }));
	
    } else if (name === 'est_date') {
		const screenestDate = value.replace(/[^\d-]/g, ''); // Remove non-numeric characters except hyphens
		const formattedDate = formatDate(screenestDate);
		if (formattedDate.length <= 8) {
            setCompany(prev => ({ ...prev, [name]: formattedDate })); // Set the unformatted value first
        }
        
        // Format the date once the user has entered all digits
        if (formattedDate.length === 10) {
            //const formattedDate1 = `${formattedDate.slice(0, 4)}-${formattedDate.slice(4, 6)}-${formattedDate.slice(6, 8)}`;
            setCompany(prev => ({ ...prev, [name]: formatDate(formattedDate) }));
        }
    } else {
        setCompany(prev => ({ ...prev, [name]: value }));
    }
    localStorage.setItem('companyData', JSON.stringify(company)); 
    // Push the new state to history
  window.history.pushState({ ...company, [name]: value }, '');
  };
  
    
    const validateForm = () => {
        const newErrors = [];
        
        console.log("Fax Number Value:", company.fax_number);
		console.log("Est Date Value:", company.est_date);

        if (!company.company_name) newErrors.push("会社名 : 会社名は必須です");
        if (!company.postal_code) newErrors.push("郵便番号: 郵便番号は必須です");
        if (!/^\d+$/.test(company.postal_code)) newErrors.push("郵便番号 : 半角数字で入力してください");
        if (!company.prefecture) newErrors.push("都道府県: 都道府県は必須です");
        if (!company.city) newErrors.push("市区町村: 市区町村は必須です");
        if (!company.alias) newErrors.push("通称: 通称は必須です");
        if (!company.address) newErrors.push("丁目・番地 : 丁目・番地は必須です");
        if (!company.phone_number) newErrors.push("電話番号: 電話番号は必須です");
        if (!/^\d{2,5}-\d{1,4}-\d{4}$/.test(company.phone_number)) newErrors.push("電話番号: 電話番号の形式で入力してください");
        if (!company.fax_number) newErrors.push("FAX番号: FAX番号は必須です");
        if (!/^\d{2,5}-\d{1,4}-\d{4}$/.test(company.fax_number)) newErrors.push("FAX番号: FAX番号の形式で入力してください");
        if (!company.email) newErrors.push("メールアドレス: メールアドレスは必須です");
        if (!/^[\w!#$%&'*+/=?^_`{|}~-]+(\.[\w!#$%&'*+/=?^_`{|}~-]+)*@[\w-]+(\.[\w-]+)*(\.[a-z]{2,})$/i.test(company.email)) newErrors.push("メールアドレス: メールアドレスの形式で入力してください。");
        if (!company.repr) newErrors.push("代表者: 代表者は必須です");
        if (/[0-9!@#$%^&*(),.?":{}|<>]/.test(company.repr)) newErrors.push("代表者：数字・記号が含まれています。ご確認ください。");
        if (!company.est_date) newErrors.push("設立年月日: 設立年月日は必須です");
        if (!/^\d{4}-\d{2}-\d{2}$/.test(company.est_date)) newErrors.push("設立年月日：半角数字かつ8桁で入力してください");
        if (!company.corpno) newErrors.push("法人番号: 法人番号は必須です");
        if (!/^\d{13}$/.test(company.corpno)) newErrors.push("法人番号: 13桁の半角数字で入力してください");
        if (!company.bussregno) newErrors.push("事業者登録番号: 事業者登録番号は必須です");
        if (!/^[T]\d{13}$/.test(company.bussregno)) newErrors.push("事業者登録番号: T+13桁の半角数字で入力してください");

		return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
           
        const formErrors = validateForm();
        if (formErrors && formErrors.length > 0) {
            setError(formErrors);
            console.log(error);
            return;
        }
        
        setLoading(true);
        setError(null);
        
        const formattedPhoneNumber = company.phone_number.replace(/-/g, '');
    	const formattedFaxNumber = company.fax_number.replace(/-/g, '');

	// Create the encrypted API key Blob (for the Node.js API call)
	const apiKeyBlob = await createfileTokenAPKBlob("NODEJS");

	// Convert Blob to Base64 for transmission in the request
	const base64ApiKey = await apiKeyBlob.text();

        try {
            const response = await nodejs_db_api.put('/submit-company-info', {
		fileTokenAPK : base64ApiKey,
		company_id: company_id,
                company_name: company.company_name,
                postal_code: company.postal_code,
                prefecture: company.prefecture,
                city: company.city,
                alias: company.alias,
                address: company.address,
                building: company.building,
                phone_number: formattedPhoneNumber,
                fax: formattedFaxNumber,
                email: company.email,
                repr: company.repr,
                est_date: company.est_date,
                corpno: company.corpno,
                bussregno: company.bussregno
            });
            
            //console.log("response : ",response)
            
            if (response.status === 200) {
                setSuccess('会社詳細情報が正常に更新されました');
                // Show confirmation message box
            	if (window.confirm("会社詳細情報が正常に更新されました")) {
                	navigate('/customer-data');}
                
            } else {
                setError('Failed to submit company information.');
            }
        } catch (error) {
            console.error('Error submitting company information:', error);
            setError('An error occurred. Please try again.');
        } finally {
            setLoading(false);
        }
    };
    
    //usePrompt('Are you sure you want to leave? Unsaved changes will be lost.');
    // Use the custom hook to handle popstate logic
  usePopStateHandlercompany(company, setCompany,isPageRefreshed);
    
        // Function to toggle the PDF section
    const togglePDFSection = () => {
        setOpenPDFSection(prevOpen => !prevOpen);
    };
    return (
		<div>
		 <CommonLayout user={user} openPDFSection={openPDFSection} togglePDFSection={togglePDFSection}>
            {/* Place your component-specific content here */}
        	<Box sx={{ flexGrow: 1, padding: 2 }}>
        	<Card className="company-info-form" style={{ display: 'flex', justifyContent: 'center' }} backdrop="static">
        	<CardContent>
         	<Container>
            <Box>
                <Typography variant="h5" component="h1" gutterBottom>
                    企業詳細情報更新
                </Typography>
                <form onSubmit={handleSubmit}>

                <Box display="flex">
                <div className="button-container">
                	<Button type="submit" variant="contained" color="primary" disabled={loading}>
                        {loading ? <CircularProgress/> : '保存'}
                    </Button>
                    <Button variant="outlined" component={Link} to="/customer-data">
                        企業詳細照会に戻る
                    </Button>
                </div>
                </Box>

                {success && (
                    <Alert severity="success">{success}</Alert>
                )}

                {error && Array.isArray(error) && error.length > 0 && (
                    <Alert severity="error">
                        <ul>
                            {error.map((error, index) => (
                                <li key={index}>{error}</li>
                            ))}
                        </ul>
                    </Alert>
                )}

                	<Box key="company_name">
                    <TextField label="会社名" name="company_name" value={company.company_name || ''} onChange={handleChange} 
						sx={{ width: 300 }} // Set width to 500 pixels
                        margin="normal"
                    />
                    </Box>
                    <Box key="postal_code">
                    <TextField
                        label="郵便番号"
                        name="postal_code"
                        value={company.postal_code || ''}
                        onChange={handleChange}
                        sx={{ width: 300 }} // Set width to 500 pixels
                        margin="normal"
                    />
                    </Box>
                    {addresses.length > 1 && (
        <select onChange={handleAddressSelect}>
          <option value="">住所を選択してください</option>
          {addresses.map((addr, index) => (
            <option key={index} value={index}>{addr.prefecture} {addr.city} {addr.alias}</option>
          ))}
        </select>
      )}
      			<Box key="prefecture">
                <TextField
                        label="都道府県"
                        name="prefecture"
                        value={company.prefecture || ''}
                        onChange={handleChange}
                        sx={{ width: 255 }} // Set width to 500 pixels
                        margin="normal"
                        //required
                    />
                    <TextField
                        label="市区町村"
                        name="city"
                        value={company.city || ''}
                        onChange={handleChange}
                        sx={{ width: 255 }} // Set width to 500 pixels
                        margin="normal"
                        //required
                    />
                    <TextField
                        label="通称"
                        name="alias"
                        value={company.alias || ''}
                        onChange={handleChange}
                        sx={{ width: 255 }} // Set width to 500 pixels
                        margin="normal"
                        //required
                    />
                    </Box>
                    <Box key="address">
                    <TextField
                        label="丁目・番地"
                        name="address"
                        value={company.address || ''}
                        onChange={handleChange}
                        sx={{ width: 255 }} // Set wi５h to 500 pixels
                        margin="normal"
                        //required
                    />
                    <TextField
                        label="建物名・号室"
                        name="building"
                        value={company.building || ''}
                        onChange={handleChange}
                        sx={{ width: 255 }} // Set width to 500 pixel
                        margin="normal"
                    />
               </Box>
               <Box key="phone_number">
                    <TextField
                        label="電話番号"
                        name="phone_number"
                        value={company.phone_number || ''}
                        onChange={handleChange}
                        sx={{ width: 300 }} // Set width to 500 pixels
                        margin="normal"
                        //required
                    />
              </Box>
              <Box key="fax_number">
                    <TextField
                        label="FAX番号"
                        name="fax_number"
                        value={company.fax_number || ''}
                        onChange={handleChange}
                        sx={{ width: 300 }} // Set width to 500 pixels
                        margin="normal"
                         InputLabelProps={{
      						shrink: true,  // Ensure label stays above the field when there is a value
    						}}
                        //required
                    />
             </Box>
             <Box key="email">
                    <TextField
                        label="メールアドレス"
                        name="email"
                        value={company.email || ''}
                        onChange={handleChange}
                        sx={{ width: 300 }} // Set width to 500 pixels
                        margin="normal"
                        //required
                    />
             </Box>
             <Box key="repr">
                <TextField
                        label="代表者"
                        name="repr"
                        value={company.repr || ''}
                        onChange={handleChange}
                        sx={{ width: 300 }} // Set width to 500 pixels
                        margin="normal"
                        //required
                    />
             </Box>
             <Box key="est_date">
                    <TextField
                        label="設立年月日 (YYYY-MM-DD)"
                        name="est_date"
                        value={company.est_date || ''}
                        onChange={handleChange}
                        sx={{ width: 300 }} // Set width to 500 pixels
                        margin="normal"
                        //required
                    />
             </Box>
             <Box key="corpno">
                    <TextField
                        label="法人番号"
                        name="corpno"
                        value={company.corpno || ''}
                        onChange={handleChange}
                        sx={{ width: 300 }} // Set width to 500 pixels
                        margin="normal"
                        //required
                    />
             </Box>
             <Box key="bussregno">
                    <TextField
                        label="事業者登録番号"
                        name="bussregno"
                        value={company.bussregno || ''}
                        onChange={handleChange}
                        sx={{ width: 300 }} // Set width to 500 pixels
                        margin="normal"
                        //required
                    />
             </Box>
                </form>
            </Box>
        </Container>
        </CardContent>
        </Card>
        </Box>
        </CommonLayout>
       </div>
    );
}

export default CompanyInfo;
