import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const usePopStateHandlercompany = (formData, setFormData,isPageRefreshed) => {
  const navigate = useNavigate();

  useEffect(() => {
    const handlePopState = (event) => {
      if (event.state) {
        //setFormData(event.state);
        //window.history.replaceState(event.state,''); // Keep user on the same page
        console.log("setform area");
        alert("変更したデータが保存されません。 前の画面に戻る場合は「企業詳細照会に戻る」を押してください。");
      } else {
		console.log("navigate area");
        navigate(-1); // If no state is found, navigate back
      }
    };
    
    const handleBeforeUnload  = (event) =>{
    console.log('BeforeUnload event detected', event); // ページリロードや閉じる操作を確認
    console.log("isPageRefreshed : ",isPageRefreshed);
    if (isPageRefreshed) {
      event.preventDefault();
      console.log("Uploading in progress or files selected");
      //setFormData(event.state);
      return (event.returnValue = '');
      }
  };

    window.addEventListener('beforeunload', handleBeforeUnload);

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate, setFormData,isPageRefreshed]);
};

export default usePopStateHandlercompany;
